import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'

import { screen1520px, screen1920px, screen400px, screen480px, screen768px } from '../constants'

import VideoEN from '../assets/videos/Ukraine-EN.mp4';
import VideoUA from '../assets/videos/Ukraine-UA.mp4';

const UkraineTrip = () => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: 'ukraineTrip' });
  const tr = (value: string): string => t(value)

  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: {relativePath: {regex: "/vyshyvanka.*png/"}}) {
          nodes {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_noBase64
                originalName
              }
            }
          }
        }
      }
    `
  )

  const getImageByName = (name: string) => {
    return data.allFile.nodes
      .find(i => i.childImageSharp.fluid.originalName === name)
      ?.childImageSharp.fluid;
  }

  return (
    <Wrapper id='ukraine'>
      <VyshyvankaWrapper fluid={getImageByName('vyshyvanka-1.png')}></VyshyvankaWrapper>
      <ContentWrapper className='section'>
        <Columns>
          <Column>
            <Title>{tr('title')}</Title>
            <TextBlock>
              <Text>{tr('text')}</Text>
              <HighlightedText>{tr('highlightedText')}</HighlightedText>
              <Text>{tr('text2')}</Text>
              <br />
              <br />
              <Text>{tr('text3')}</Text>
            </TextBlock>

            <Author>{tr('author')}</Author>
            <AuthorTitle>{tr('authorTitle')}</AuthorTitle>
          </Column>
          <Column2>
            <Column2ContentWrapper>
              <VideoWrapper>
                <VideoElement autoPlay muted loop playsInline
                  src={i18n.language === 'en' ? VideoEN : VideoUA} type='video/mp4'
                />
              </VideoWrapper>
              <Stats>
                <Group>
                  <Number>50</Number>
                  <GroupText>{tr('statsDays')}</GroupText>
                </Group>
                <Group>
                  <Number>6000</Number>
                  <GroupText>{tr('statsKm')}</GroupText>
                </Group>
                <Group>
                  <Number>20</Number>
                  <GroupText>{tr('statsRegions')}</GroupText>
                </Group>
                <Group>
                  <Number>300</Number>
                  <GroupText>{tr('statsLocations')}</GroupText>
                </Group>
              </Stats>
            </Column2ContentWrapper>
          </Column2>
        </Columns>
      </ContentWrapper>
      <VyshyvankaWrapper fluid={getImageByName('vyshyvanka-2.png')}></VyshyvankaWrapper>
    </Wrapper>
  )
}

export default UkraineTrip

const Wrapper = styled.div`
  font-style: normal;
  position: relative;
`

const VyshyvankaWrapper = styled(BackgroundImage)`
  font-style: normal;
  background-position: 0 0;
  background-size: cover;
  position: relative;
  height: 32px;

  @media screen and (max-width: ${screen480px}) {
    height: 15px;
  }
`

const ContentWrapper = styled.div`
  padding-top: 50px;
  color: #171717;
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${screen768px}) {
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  max-width: min(40vw, calc(${screen1520px} / 2 - 50px));
  @media screen and (max-width: ${screen768px}) {
    max-width: 95vw;
  }
`

const Title = styled.h2`
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  margin-top: 50px;
  font-size: 5.0rem;
  @media screen and (max-width: ${screen1920px}){
    margin-top: 20px;
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    margin-top: 10px;
    font-size: 3.1rem;
  }
`

const TextBlock = styled.div`
  font-weight: 400;
  line-height: 100%;
  padding-right: 15px;
  margin-top: 50px;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
    margin-top: 30px;
  }
`
const Text = styled.span``
const HighlightedText = styled.span`
  color: #FF8557;
`
const Author = styled.div`
  font-weight: 800;
  line-height: 105%;
  text-transform: uppercase;
  margin-top: 35px;
  margin-bottom: 5px;
  font-size: 3.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.875rem;
  }
`

const AuthorTitle = styled.div`
  font-weight: 400;
  line-height: 120%;
  margin-bottom: 30px;
  font-size: 1.6rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 0.833rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.0rem;
  }
`

const Column2 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40vw;
  @media screen and (max-width: ${screen768px}) {
    max-width: 95vw;
  }
`

const Column2ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const VideoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  max-width: calc(${screen1520px} / 4 - 80px);
  @media screen and (max-width: ${screen768px}) {
    max-width: 50vw;
    max-height: 80vh;
  }
`

const VideoElement = styled.video`
  width: 100%;
  height: 100%;
`

const Stats = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-bottom: 50px;
`
const Group = styled.div`
  margin-left: 10px;
`

const Number = styled.div`
  color: #FF8557;
  font-weight: 800;
  font-size: 8rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 4rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 6.0rem;
  }
  @media screen and (max-width: ${screen400px}){
    font-size: 3.8rem;
  }
`

const GroupText = styled.div`
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`
