import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { screen1920px, screen480px } from '../constants';

const ForWhom = () => {
  const { t } = useTranslation("translation", { keyPrefix: 'forWhom' });
  const tr = (value: string): string => t(value)

  return (
    <Wrapper id='for-whom'>
      <ContentWrapper className='section'>
        <TextGroup>
          <Column>
            <Title>{tr('title')}</Title>
            <Text>{tr('text1')}</Text>
          </Column>
          <EmptyColumn></EmptyColumn>
        </TextGroup>

        <Group>
          <Column>
            <Number>01</Number>
            <Text>{tr('text2')}</Text>
          </Column>
          <Column>
            <Number>02</Number>
            <Text>{tr('text3')}</Text>
          </Column>
        </Group>
      </ContentWrapper>
    </Wrapper>
  )
}

export default ForWhom

const Wrapper = styled.div`
  font-style: normal;
  color: #171717;
`

const ContentWrapper = styled.div`
  margin-top: 50px;
  margin-bottom: 30px;
`

const Title = styled.h1`
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 10px;
  font-size: 5.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 3.1rem;
  }
`

const Text = styled.p`
  font-weight: 400;
  line-height: 120%;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const Number = styled.p`
  color: #FF8557;
  font-weight: bold;
  font-size: 4rem;
  margin: 20px 0 5px 0;
  letter-spacing: 0.4rem;
  -webkit-text-stroke-width: 3px;
`

const Group = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const TextGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${screen480px}){
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  padding-right: 20px;
`
const EmptyColumn = styled.div``
