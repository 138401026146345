import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import { screen1920px, screen480px, screen768px } from '../constants';
import { noticeAppStoreButtonClicked, noticeDonateButtonClicked, noticeGooglePlayButtonClicked } from '../utils/analytics';

const DownloadAndSupport = () => {
  const { t } = useTranslation("translation", { keyPrefix: 'downloadAndSupport' });
  const tr = (value: string): string => t(value)

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background2.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Wrapper id="download" fluid={data.desktop.childImageSharp.fluid}>
      <ContentWrapper className='section'>
        <Column>
          <Title>{tr('title1')}</Title>
          <Text>{tr('text1')}</Text>
          <StoreButtons>
            <StoreButton onClick={noticeAppStoreButtonClicked} href='https://apps.apple.com/ua/app/ttravel/id1567879792' target='_blank'>
              <StaticImage src='../assets/images/apple-store.png' alt='apple store' />
            </StoreButton>
            <StoreButton onClick={noticeGooglePlayButtonClicked} href='https://play.google.com/store/apps/details?id=me.ttravel.rnjs' target='_blank'>
              <StaticImage src='../assets/images/google-play.png' alt='play market' />
            </StoreButton>
          </StoreButtons>
        </Column>
        <Column>
          <Title>{tr('title2')}</Title>
          <Text>{tr('text2')}</Text>
          <Button onClick={noticeDonateButtonClicked} href='https://secure.wayforpay.com/button/bb33425a65d40' target='_blank'>{tr('support')}</Button>
        </Column>
      </ContentWrapper>
    </Wrapper>
  )
}

export default DownloadAndSupport

const Wrapper = styled(BackgroundImage)`
  font-style: normal;
  background-position: 45% 0;
  background-size: cover;
  position: relative;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Column = styled.div`
  margin-top: 30px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${screen480px}) {
    padding-right: 5px;
  }
`

const Title = styled.h3`
  font-weight: 800;
  line-height: 105%;
  color: #FFFFFF;
  text-transform: uppercase;
  font-size: 3.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.9rem;
  }
`

const Text = styled.h4`
  margin: 5px 0;
  font-weight: 400;
  line-height: 120%;
  color: #FFFFFF;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const StoreButtons = styled.div`
  display: flex;
  @media screen and (max-width: ${screen768px}) {
    display: grid;
    grid-template-columns: 1fr;
  }
  margin-bottom: 10px;
`

const StoreButton = styled(OutboundLink)`
  margin: 10px 10px 10px 0;
  width: 228px;
  @media screen and (max-width: ${screen1920px}) {
    width: 118.75px;
    margin: 6.25px 5px 6.25px 0;
  }
  @media screen and (max-width: ${screen480px}) {
    width: 142px;
  }
`

const Button = styled(OutboundLink)`
  margin-top: 5px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FF8557;

  width: 227px;
  height: 67px;
  border-radius: 15px;
  font-size: 2.3rem;

  @media screen and (max-width: ${screen1920px}) {
    width: 118.58px;
    height: 34.92px;
    border-radius: 7.8125px;
    font-size: 1.2rem;
  }

  @media screen and (max-width: ${screen480px}) {
    width: 139.5px;
    height: 41.5px;
    border-radius: 9.375px;
    font-size: 1.4rem;
  }

  text-decoration: none;

  font-weight: 800;
  line-height: 100%;
  color: #171717;
`