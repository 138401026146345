import React from 'react'
import styled from 'styled-components'

import { useTranslation } from 'react-i18next'
import { screen1000px, screen1720px, screen1920px, screen480px, screen768px } from '../constants'
import useProgressiveImage from '../utils/useProgressiveImage'
import useWindowDimensions from '../utils/useWindowDimensions'

const Intro = () => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: 'intro' });
  const tr = (value: string): string => t(value)
  const { width } = useWindowDimensions();

  const backgroundImage = useProgressiveImage(
    width <= 480 ? '/images/background-preview-768.png'
      : width <= 1000 ? '/images/background-preview-1200.png'
        : width <= 1920 ? '/images/background-preview-1920.png'
          : '/images/background.png'
  )

  return (
    <StyledBackgroundSection
      id='intro'
      backgroundImageUrl={backgroundImage || '/images/background-preview.png'}
    >
      <ContentWrapper className='section'>
        <div>
          <IntroTitle>{tr('title').split('\n').map((i, index) => <div key={index}>{i}</div>)}</IntroTitle>
          <IntroText>{tr('text')}</IntroText>
        </div>

        <Languages>
          <Lang as='a' active={i18n.language === 'uk'} href='/'>UA</Lang>
          <Separator>|</Separator>
          <Lang as='a' active={i18n.language === 'en'} href='/en/'>EN</Lang>
        </Languages>
      </ContentWrapper>
    </StyledBackgroundSection>
  )
}

export default Intro

const StyledBackgroundSection = styled.div<{ backgroundImageUrl: string }>`
  margin-top: -73.75px;
  width: 100%;

  height: 900px;
  padding-top: 270px;
  @media screen and (max-width: ${screen1920px}){
    height: 468.75px;
    padding-top: 150.88px;
  }
  @media screen and (max-width: ${screen480px}){
    height: 562px;
    padding-top: 176px;
  }

  /* background-position: bottom 0 left 69.5%; */
  background-position: 55% 100%;
  @media screen and (max-width: calc(${screen1000px} - 50px)){
    background-position: 63% 100%;
  }
  @media screen and (max-width: ${screen768px}){
    background-position: 53% 100%;
  }
  @media screen and (max-width: ${screen480px}){
    background-position: 69% 100%;
  }
  /* background-repeat: repeat-y; */
  background-size: cover;
  /* background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)); */
  background-image: url(${i => i.backgroundImageUrl});

  display: flex;
  justify-content: flex-start;
  align-items: normal;
  position: relative;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  max-height: 530px;
  @media screen and (max-width: ${screen1920px}){
    max-height: 280px;
  }
  @media screen and (max-width: ${screen480px}){
    max-height: 330px;
  }
`

const IntroTitle = styled.h1`
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  color: #FFFFFF;
  /* -webkit-text-stroke: #FFFFFF 1px; */
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25); */

  font-size: 6.0rem;
  width: 80vw;

  @media screen and (max-width: ${screen1920px}){
    font-size: 3.1rem;
    width: 80vw;
  }

  @media screen and (max-width: ${screen480px}){
    font-size: 3.7rem;
    width: 80vw;
  }
`

const IntroText = styled.h1`
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #FFFFFF;
  margin: 20px 0;

  font-size: 2.3rem;
  width: 45vw;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
    width: 45vw;
    max-width: 520px;
  }
  @media screen and (max-width: ${screen768px}){
    width: 55vw;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
    width: 80vw;
  }
`

const Languages = styled.div`
  display: flex;
`

const LangTitle = styled.h3`
  font-weight: 800;
  line-height: 105%;
  text-transform: uppercase;
  color: #FFFFFF;
  font-size: 3.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.9rem;
  }
`

const Lang = styled(LangTitle) <{ active: boolean }>`
  font-weight: ${p => p.active ? 800 : 400};
  cursor: pointer;
  text-decoration: none;
`

const Separator = styled(LangTitle)`
  font-weight: 400;
  margin: 0 9.38px;
`
