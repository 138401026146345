import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { screen1920px, screen480px, screen768px } from '../constants'
import { noticeAppStoreButtonClicked, noticeGooglePlayButtonClicked } from '../utils/analytics';
import useWindowDimensions from '../utils/useWindowDimensions';
import { isBrowser } from '../utils/utils';

const UkraineMovie = () => {
  const { t } = useTranslation("translation", { keyPrefix: 'ukraineMovie' });
  const tr = (value: string): string => t(value)
  const { width, height } = useWindowDimensions()
  const [videoSize, setVideoSize] = useState<{ width: number; height: number }>({ width: 560, height: 315 })

  const data = useStaticQuery(
    graphql`
      query {
        allFile(filter: {relativePath: {regex: "/(2vyshyvanka.*)|(background5.*)png/"}}) {
          nodes {
            childImageSharp {
              fluid(quality: 100, maxWidth: 3840) {
                ...GatsbyImageSharpFluid_noBase64
                originalName
              }
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    let videoHeight = height * 0.35
    let videoWidth = width
    if (videoWidth >= 1000) videoWidth = videoHeight / 0.5625
    else if (videoWidth >= 768) videoWidth = videoWidth * 0.4
    // if (videoWidth >= 1920) videoWidth = Math.min(1520, width) / 2 - 50

    if(width < 480) videoWidth = width - 40
    else if (width < 768) videoWidth = width - 68

    setVideoSize({ width: videoWidth, height: videoHeight })
  }, [width])

  const getImageByName = (name: string) => {
    return data.allFile.nodes
      .find(i => i.childImageSharp.fluid.originalName === name)
      ?.childImageSharp.fluid;
  }

  const renderPlayer = () => {
    if (!isBrowser) {
      return null
    }

    return <iframe width={videoSize.width} height={videoSize.height} src="https://www.youtube.com/embed/hkFdrOHQnAY" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  }

  return (
    <Wrapper fluid={getImageByName('background5.png')}>
      <VyshyvankaWrapper fluid={getImageByName('2vyshyvanka-1.png')}></VyshyvankaWrapper>
      <ContentWrapper className='section'>
        <Columns>
          <Column>
            <Title>{tr('title')}</Title>
            <Text>{tr('text')}</Text>
            <Horizontal>
              <Lang>{tr('lang')}</Lang>
              <Flag><StaticImage src='../assets/images/flag-ua.png' alt='ua' /></Flag>
            </Horizontal>
            <Horizontal>
              <Subs>{tr('subs')}</Subs>
              <Flag><StaticImage src='../assets/images/flag-gb.png' alt='gb' /></Flag>
            </Horizontal>
          </Column>
          <Column2>
            {renderPlayer()}
          </Column2>
          <Download>
            <Title2>{tr('title2')}</Title2>
            <StoreButtons>
              <StoreButton onClick={noticeAppStoreButtonClicked} href='https://apps.apple.com/ua/app/ttravel/id1567879792' target='_blank'>
                <StaticImage src='../assets/images/apple-store.png' alt='apple store' />
              </StoreButton>
              <StoreButton onClick={noticeGooglePlayButtonClicked} href='https://play.google.com/store/apps/details?id=me.ttravel.rnjs' target='_blank'>
                <StaticImage src='../assets/images/google-play.png' alt='play market' />
              </StoreButton>
            </StoreButtons>
            <Text2>{tr('text2')}</Text2>
          </Download>
        </Columns>
      </ContentWrapper>
      <VyshyvankaWrapper fluid={getImageByName('2vyshyvanka-2.png')}></VyshyvankaWrapper>
    </Wrapper>
  )
}

export default UkraineMovie

const Wrapper = styled(BackgroundImage)`
  font-style: normal;
  background-position: 40% 0;
  background-size: cover;
  position: relative;
`

const VyshyvankaWrapper = styled(BackgroundImage)`
  font-style: normal;
  background-position: 0 0;
  background-size: cover;
  position: relative;
  height: 32px;

  @media screen and (max-width: ${screen480px}) {
    height: 15px;
  }
`

const ContentWrapper = styled.div`
  padding-top: 70px;
  padding-bottom: 70px;
  color: #FFFFFF;
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media screen and (max-width: ${screen768px}) {
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  margin-bottom: 20px;
`
const Column2 = styled.div``

const Title = styled.div`
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 40px;
  font-size: 5.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 3.1rem;
  }
`

const Text = styled.h4`
  margin-bottom: 20px;
  font-weight: 400;
  line-height: 120%;
  padding-right: 10px;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const Lang = styled(Text)`
  margin-bottom: 0;
`

const Subs = styled(Text)`
  margin-bottom: 0;
`

const Flag = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23px;
  height: 28px;
  margin-left: 10px;
`

const Horizontal = styled.div`
  display: flex;
  align-items: center;
`

const Download = styled.div``
const Title2 = styled.h3`
  font-weight: 800;
  line-height: 105%;
  text-transform: uppercase;
  color: #FF8557;
  margin-top: 30px;
  padding-right: 10px;
  font-size: 3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.9rem;
  }
`
const Text2 = styled.h4`
  font-weight: 400;
  line-height: 120%;
  max-width: 657px;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const StoreButtons = styled.div`
  margin: 10px 0;
  display: flex;
  max-width: 80vw;
`

const StoreButton = styled.a`
  margin-right: 10px;
  width: 170px;
`
