import React, { useEffect } from 'react'

import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Intro from "../components/Intro"
import ForWhom from "../components/ForWhom"
import DownloadAndSupport from "../components/DownloadAndSupport"
import AboutUs from "../components/AboutUs"
import Trips from "../components/Trips"
import InsideItinerary from "../components/InsideItinerary"
import HowConstructorWorks from "../components/HowConstructorWorks"
import Plans from "../components/Plans"
import UkraineTrip from "../components/UkraineTrip"
import UkraineMovie from "../components/UkraineMovie"
import Feedbacks from "../components/Feedbacks"
import Footer from "../components/Footer"

const IndexPage = () => {
  // useEffect(() => {
  //   const lang = (navigator.languages && navigator.languages[0]) || navigator.language
  //   if (!lang.toLowerCase().includes('uk') && document.location.pathname !== '/en') {
  //     document.location.href = '/en';
  //   }
  // }, [])

  return (
    <Layout>
      <Seo title="tTravel" />
      <Intro />
      <ForWhom />
      <DownloadAndSupport />
      <AboutUs />
      <Trips />
      <InsideItinerary />
      <HowConstructorWorks />
      <Plans />
      <UkraineTrip />
      <UkraineMovie />
      <Feedbacks />
      <Footer />
    </Layout>
  )
}

export const Head = () => <Seo title="tTravel" />

export default IndexPage
