import React from 'react'
import styled from 'styled-components'
import { screen1920px, screen480px } from '../constants'
import LogoIcon from '../assets/images/logo.svg'

const Logo = () => {
  return (
    <Wrapper href='/'>
      <LogoIconComponent />
    </Wrapper>
  )
}

export default Logo


const Wrapper = styled.a`
  display: flex;
  align-items: center;
  margin-right: 5px;
  text-decoration: none;
`

const LogoIconComponent = styled(LogoIcon)`
  width: 144px;
  height: 63.22px;
  @media screen and (max-width: ${screen1920px}){
    width: 75px;
    height: 32.93px;
  }
  @media screen and (max-width: ${screen480px}){
    width: 90px;
    height: 39.51px;
  }
  margin-right: 5px;
`
