import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { screen1920px, screen480px, screen768px } from '../constants'

const Feedbacks = () => {
  const { t } = useTranslation("translation", { keyPrefix: 'feedbacks' });
  const tr = (value: string): string => t(value)

  return (
    <Wrapper id='feedbacks'>
      <ContentWrapper className='section'>
        <Title>{tr('title')}</Title>
        <Columns>
          <Column>
            <Group2>
              <Image><StaticImage alt='review 1' src='../assets/feedbacks/review-1.png' /></Image>
              <Image><StaticImage alt='review 2' src='../assets/feedbacks/review-2.png' /></Image>
              <Image><StaticImage alt='review 3' src='../assets/feedbacks/review-3.png' /></Image>
              <Image><StaticImage alt='review 4' src='../assets/feedbacks/review-4.png' /></Image>
            </Group2>
          </Column>
          <Group1>
            <ColumnOrRow>
              <Image><StaticImage alt='review 5' src='../assets/feedbacks/review-5.png' /></Image>
              <Image><StaticImage alt='review 6' src='../assets/feedbacks/review-6.png' /></Image>
            </ColumnOrRow>
            <ColumnOrRow>
              <Image><StaticImage alt='review 7' src='../assets/feedbacks/review-7.png' /></Image>
              <Image><StaticImage alt='review 8' src='../assets/feedbacks/review-8.png' /></Image>
            </ColumnOrRow>
            <ColumnOrRow>
              <Image><StaticImage alt='review 9' src='../assets/feedbacks/review-9.png' /></Image>
              <Image><StaticImage alt='review 10' src='../assets/feedbacks/review-10.png' /></Image>
            </ColumnOrRow>
          </Group1>
        </Columns>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Feedbacks


const Wrapper = styled.div`
  font-style: normal;
  position: relative;
`

const ContentWrapper = styled.div`
  padding-top: 80px;
  padding-bottom: 80px;
  color: #000000;
`
const Title = styled.h2`
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  margin-bottom: 30px;
  margin-left: 10px;
  font-size: 5rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 3.1rem;
  }
`

const Columns = styled.div`
  display: flex;

  @media screen and (max-width: ${screen768px}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const ColumnOrRow = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: ${screen768px}) {
    flex-direction: row;
  }
`

const Group1 = styled.div`
  display: flex;

  @media screen and (max-width: ${screen768px}) {
    display: grid;
    grid-template-columns: 1fr;
  }
`

const Group2 = styled.div`
  display: grid;
  grid-template-columns: 1fr;
`

const Image = styled.div`
  display: flex;
`
