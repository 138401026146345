import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { screen1920px, screen400px, screen480px, screen700px, screen768px } from '../constants';

const InsideItinerary = () => {
  const { t } = useTranslation("translation", { keyPrefix: 'insideItinerary' });
  const tr = (value: string): string => t(value)

  const data = useStaticQuery(
    graphql`
      query {
        screenshots: file(relativePath: { eq: "screenshots.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
        screenshots3: file(relativePath: { eq: "screenshots3.png" }) {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    `
  )

  return (
    <Wrapper id='inside-itinerary'>
      <ContentWrapper className='section'>
        <Columns>
          <Column1>
            <Title>{tr('title')}</Title>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item1')}</Text>
            </Item>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item2')}</Text>
            </Item>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item3')}</Text>
            </Item>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item4')}</Text>
            </Item>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item5')}</Text>
            </Item>
          </Column1>
          <Section1>
            <GatsbyImage image={data.screenshots.childImageSharp.gatsbyImageData} alt='screenshots' />
          </Section1>
          <Section3>
            <GatsbyImage image={data.screenshots3.childImageSharp.gatsbyImageData} alt='screenshots' />
          </Section3>
        </Columns>
      </ContentWrapper>
    </Wrapper>
  )
}

export default InsideItinerary

const Wrapper = styled.div``

const ContentWrapper = styled.div`
  font-style: normal;
  color: #171717;
  @media screen and (max-width: ${screen768px}){
    padding-right: 0;
  }
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`

const Column = styled.div`
  max-height: 900px;
  @media screen and (max-width: ${screen1920px}){
    max-height: 468.75px;
  }
  @media screen and (max-width: ${screen768px}){
    max-height: 392px;
  }
  @media screen and (max-width: ${screen400px}){
    /* width: 50vw; */
  }
`
const Column1 = styled.div`
  padding-right: 20px;
`

const Title = styled.h2`
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  margin-top: 52px;
  margin-bottom: 30px;
  margin-right: 5px;
  max-width: min(45vw, 550px);

  @media screen and (max-width: ${screen700px}) {
    margin-top: 40px;
    margin-bottom: 5px;
  }

  font-size: 5.0rem;
  @media screen and (max-width: ${screen1920px}){
    max-width: min(45vw, 350px);
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 3.1rem;
  }
  @media screen and (max-width: ${screen400px}){
    font-size: 2.1rem;
  }
`
const Item = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
`

const LineWrapper = styled.div`
  height: 1px;
`
const Line = styled.div`
  width: 100vw;
  margin-left: -101vw;
  height: 1px;
  background-color: black;
`
const Text = styled.h4`
  font-weight: 400;
  line-height: 120%;

  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const Section = styled(Column)`
  display: none;
  max-height: 90vh;
`

const Section1 = styled(Section)`
  @media screen and (min-width: calc(${screen768px} + 1px)) {
    display: block;
  }
`
const Section3 = styled(Section)`
  @media screen and (max-width: ${screen768px}){
    display: block;
  }
`
