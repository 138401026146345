import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { screen1520px, screen1920px, screen480px, screen700px, screen768px } from '../constants'

import CrossIcon from '../assets/images/cross.svg'

const siteMap = [
  { title: 'downloadApp', link: '#download', css: { color: '#FF8557', fontWeight: 600 } },
  { title: 'aboutUs', link: '#about' },
  { title: 'availableTrips', link: '#trips' },
  { title: 'howConstructorWorks', link: '#constructor' },
  { title: 'insideItinerary', link: '#inside-itinerary' },
  { title: 'UkraineTrip', link: '#ukraine' },
  { title: 'Feedbacks', link: '#feedbacks' },
]

const Menu: React.FC<{ closeRequested: () => void; closed: boolean }> = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: 'menu' });
  const tr = (value: string): string => t(value)

  return (
    <Wrapper closed={props.closed} onClick={props.closeRequested}>
      <MenuWrapper>
        <CloseButton onClick={props.closeRequested}>
          <CrossIconComponent />
        </CloseButton>
        {siteMap.map((i, index) => <NavLink key={index} to={i.link} css={i.css}>{tr(i.title)}</NavLink>)}
      </MenuWrapper>
    </Wrapper>
  )
}

export default Menu

const Wrapper = styled.div<{ closed: boolean }>`
  display: ${i => i.closed ? 'none' : 'block'};
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: transparent;
  position: fixed;
  z-index: 100;

  padding-top: 15px;
  /* @media screen and (max-width: ${screen768px}){
    padding-top: 34px;
  }
  @media screen and (max-width: ${screen480px}){
    padding-top: 20px;
  } */
`

const MenuWrapper = styled.div`
  position: fixed;
  z-index: 101;
  /* right: max(2vw, calc((100vw - ${screen1520px})/2)); */
  right: calc((100vw - ${screen1520px})/2);

  @media screen and (max-width: ${screen1920px}){
    right: calc((100vw - ${screen700px})/2);
  }

  @media screen and (max-width: ${screen768px}){
    right: 34px;
    margin-left: 34px;
  }

  @media screen and (max-width: ${screen480px}){
    right: 20px;
    margin-left: 20px;
  }

  padding-top: 21px;
  padding-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;

  background: rgba(23, 23, 23, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 15px;

  @media screen and (max-width: ${screen480px}){
    padding-top: 21px;
    padding-bottom: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }
`

const CloseButton = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
`
const CrossIconComponent = styled(CrossIcon)`
  width: 16px;
  height: 16px;
  @media screen and (max-width: ${screen480px}){
    width: 22.91px;
    height: 22.91px;
  }
`

const NavLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  font-weight: 400;
  line-height: 120%;
  color: #FFFFFF;
  margin: 0 5px;
  margin-top: 10px;
  &:nth-child(2){
    margin-top: 0px;
  }
  text-decoration: none;
  max-width: 50vw;

  font-size: 1.19792rem;
  @media screen and (max-width: ${screen480px}){
    margin-top: 15px;
    font-size: 2.3rem;
    max-width: max-content;
  }

  @media screen and (min-width: ${screen768px}){
    &:nth-child(3),
    &:nth-child(2){
      display: none;
    }
  }
`