import { StaticImage } from 'gatsby-plugin-image';
import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import useWindowDimensions from '../utils/useWindowDimensions';
import { screen1920px, screen360px, screen480px, screen768px } from '../constants';

import LinkedInLogo from '../assets/images/linkedin.svg'
import LinkedInSmallLogo from '../assets/images/linkedin-small.svg'
import { noticeLinkedInBuratynskyClicked, noticeLinkedInShambalovClicked } from '../utils/analytics';

const AboutUs = () => {
  const { t } = useTranslation("translation", { keyPrefix: 'aboutUs' });
  const tr = (value: string): string => t(value)
  const { width } = useWindowDimensions();

  return (
    <Wrapper id='about'>
      <ContentWrapper className='section'>
        <Title>{tr('title')}</Title>
        <Columns>
          <Column>
            <Text>{tr('text').split('\n').map((i, index) => <TextP key={index}>{i}</TextP>)}</Text>
          </Column>
          <Column>
            <CoFounder>
              <CoPhoto>
                <StaticImage src='../assets/images/co-founder1.png' alt='Co Founder 1' />
              </CoPhoto>
              <CoFounderInfo>
                <CoFounderCard>
                  <CoFounderName>{tr('coFounder1Name')}</CoFounderName>
                  <CoFounderTitle>Co-founder</CoFounderTitle>
                  <CoFounderPersonalTitle>{tr('coFounder1Title')}</CoFounderPersonalTitle>
                </CoFounderCard>
                <LinkedInButton onClick={noticeLinkedInBuratynskyClicked} target='_blank' href='https://www.linkedin.com/in/buratynskyi/'>
                  {width <= 1920 ? <LinkedInSmallLogo /> : <LinkedInLogo />}
                </LinkedInButton>
              </CoFounderInfo>
            </CoFounder>
            <CoFounder>
              <CoPhoto>
                <StaticImage src='../assets/images/co-founder2.png' alt='Co Founder 2' />
              </CoPhoto>
              <CoFounderInfo>
                <CoFounderCard>
                  <CoFounderName>{tr('coFounder2Name')}</CoFounderName>
                  <CoFounderTitle>Co-founder</CoFounderTitle>
                  <CoFounderPersonalTitle>{tr('coFounder2Title')}</CoFounderPersonalTitle>
                </CoFounderCard>
                <LinkedInButton onClick={noticeLinkedInShambalovClicked} target='_blank' href='https://www.linkedin.com/in/artem-shambalov/'>
                  {width <= 1920 ? <LinkedInSmallLogo /> : <LinkedInLogo />}
                </LinkedInButton>
              </CoFounderInfo>
            </CoFounder>
          </Column>
        </Columns>
      </ContentWrapper>
    </Wrapper>
  )
}

export default AboutUs

const Wrapper = styled.div`
  font-style: normal;
  color: #171717;
  padding-top: 60px;
  padding-bottom: 20px;
`

const ContentWrapper = styled.div``

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: ${screen768px}){
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  margin: 10px 0;
  padding-right: 20px;
  @media screen and (max-width: ${screen480px}) {
    padding-right: 5px;
  }
`

const Title = styled.div`
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 30px;
  font-size: 5.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 3.1rem;
  }
`

const Text = styled.div`
  font-weight: 400;
  line-height: 120%;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const TextP = styled.p`
  &:after {
    content: "";
    display: block;
    height: 1em;
  }
`

const CoFounder = styled.div`
  margin-bottom: 30px;

  display: grid;
  grid-template-columns: auto 1fr;
`

const CoPhoto = styled.div`
  margin: 5px;

  min-width: 228px;
  min-height: 228px;
  max-width: 228px;
  max-height: 228px;
  width: 228px;
  height: 228px;
  @media screen and (max-width: ${screen1920px}){
    min-width: 118.75px;
    min-height: 118.75px;
    max-width: 118.75px;
    max-height: 118.75px;
    width: 118.75px;
    height: 118.75px;
  }
  @media screen and (max-width: ${screen480px}){
    min-width: 134.19px;
    min-height: 134.19px;
    max-width: 134.19px;
    max-height: 134.19px;
    width: 134.19px;
    height: 134.19px;
  }
`

const CoFounderInfo = styled.div`
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const CoFounderCard = styled.div``

const CoFounderName = styled.div`
  font-weight: 800;
  line-height: 120%;
  text-transform: uppercase;
  font-size: 3.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.563rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.9rem;
  }
  @media screen and (max-width: ${screen360px}){
    font-size: 1.2rem;
  }
`
const CoFounderTitle = styled.div`
  font-weight: 400;
  font-size: 2.3rem;
  line-height: 120%;
  margin-bottom: 10px;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.198rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`
const CoFounderPersonalTitle = styled.div`
  /* font-family: 'Nunito'; */
  font-weight: 400;
  line-height: 100%;
  margin-bottom: 15px;
  font-size: 1.6rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 0.833rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.0rem;
  }
`

const LinkedInButton = styled.a`
  background: #FF8557;
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;
  width: 168px;
  height: 44.21px;
  @media screen and (max-width: ${screen1920px}){
    width: 87.5px;
    height: 23.03px;
    border-radius: 5.21px;
  }
  @media screen and (max-width: ${screen480px}) {
    width: 104.86px;
    height: 26.92px;
    border-radius: 6.25px;
  }
`
