import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { screen1920px, screen480px } from '../constants';

import VideoEN from '../assets/videos/Constructor-EN.mp4';
import VideoUA from '../assets/videos/Constructor-UA.mp4';

const HowConstructorWorks = () => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: 'howConstructorWorks' });
  const tr = (value: string): string => t(value)

  return (
    <Wrapper id='constructor'>
      <ContentWrapper className='section'>
        <Columns>
          <Column>
            <Title>{tr('title')}</Title>
            <Item>
              <Number><span>1</span></Number>
              <Text>{tr('item1')}</Text>
            </Item>
            <Item>
              <Number><span>2</span></Number>
              <Text>{tr('item2')}</Text>
            </Item>
            <Item>
              <Number><span>3</span></Number>
              <Text>{tr('item3')}</Text>
            </Item>
            <Item>
              <Number><span>4</span></Number>
              <Text>{tr('item4')}</Text>
            </Item>
            <Item>
              <Number><span>5</span></Number>
              <Text>{tr('item5')}</Text>
            </Item>
            <Placeholder />
          </Column>
          <Column2>
            <VideoElement autoPlay muted loop playsInline
              src={i18n.language === 'en' ? VideoEN : VideoUA} type='video/mp4'
            />
          </Column2>
        </Columns>
      </ContentWrapper>
    </Wrapper>
  )
}

export default HowConstructorWorks

const Wrapper = styled.div`
  font-style: normal;
  position: relative;
  background-color: #000000;
`

const ContentWrapper = styled.div`
  color: #FFFFFF;
  font-style: normal;
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding-top: 50px;
  padding-bottom: 80px;

  @media screen and (max-width: ${screen480px}){
    grid-template-columns: 1fr;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
`

const Column2 = styled(Column)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.div`
  font-weight: 900;
  line-height: 100%;
  margin-bottom: 50px;
  padding-right: 50px;
  text-transform: uppercase;

  font-size: 5.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 3.1rem;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
  padding-right: 50px;

  margin-bottom: 30px;
  @media screen and (max-width: ${screen1920px}){
    margin-bottom: 20px;
  }
  @media screen and (max-width: ${screen480px}){
    margin-bottom: 20px;
  }
`

const Number = styled.div`
  background: #FF8557;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;

  min-width: 70px;
  min-height: 70px;
  max-width: 70px;
  max-height: 70px;
  border-radius: 70px;
  @media screen and (max-width: ${screen1920px}){
    min-width: 36.46px;
    min-height: 36.46px;
    max-width: 36.46px;
    max-height: 36.46px;
    border-radius: 36.46px;
  }
  @media screen and (max-width: ${screen480px}){
    min-width: 43.75px;
    min-height: 43.75px;
    max-width: 43.75px;
    max-height: 43.75px;
    border-radius: 43.75px;
  }

  span {
    line-height: 100%;
    color: #171717;
    font-size: 3.0rem;
    @media screen and (max-width: ${screen1920px}){
      font-size: 1.563rem;
    }
    @media screen and (max-width: ${screen480px}){
      font-size: 1.9rem;
    }
  }
`

const Text = styled.h4`
  font-weight: 400;
  line-height: 100%;
  padding-right: 10px;

  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.198rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const VideoElement = styled.video`
  max-height: 60vh;
  max-width: 37vw;
  @media screen and (max-width: ${screen480px}){
    max-height: 90vh;
    max-width: 90vw;
  }
`

const Placeholder = styled.div`
  width: 50px;
  height: 10px;
`
