import { Link } from 'gatsby'
import React from 'react'
import styled from 'styled-components'
import { screen1000px, screen1200px, screen1720px, screen1920px, screen480px, screen768px } from '../constants'

import BurgerIcon from '../assets/images/burger.svg'
import { useTranslation } from 'react-i18next'
import Logo from './Logo'

const links = [
  { title: 'downloadApp', link: '#download', css: { color: '#FF8557', fontWeight: 600 } },
  { title: 'aboutUs', link: '#about' },
  { title: 'availableTrips', link: '#trips' },
  { title: 'howConstructorWorks', link: '#constructor' },
]

const Header: React.FC<{ openMenu: () => void }> = (props) => {
  const { t } = useTranslation("translation", { keyPrefix: 'header' });
  const tr = (value: string): string => t(value)

  return (
    <NavBar className='section'>
      <Logo />
      <NavLinks>
        {links.map((i, index) => <NavLink key={index} to={i.link} css={i.css}>{tr(i.title)}</NavLink>)}
      </NavLinks>
      <MenuButton onClick={props.openMenu}>
        <BurgerIconComponent />
      </MenuButton>
    </NavBar>
  )
}

export default Header

const NavBar = styled.nav`
  background: transparent;
  height: 73.75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  position: relative;
`

const NavLinks = styled.div`
  width: 100%;
  display: none;

  @media screen and (min-width: ${screen768px}){
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const NavLink = styled(Link)`
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: #FFFFFF;
  margin-right: 15px;
  text-decoration: none;
  
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.198rem;
  }

  @media screen and (max-width: ${screen480px}){
    font-size: 2.3rem;
  }
`

const MenuButton = styled.div`
  cursor: pointer;
  display: flex;
`

const BurgerIconComponent = styled(BurgerIcon)`
  @media screen and (max-width: ${screen1920px}){
    width: 16.87px;
    height: 4.69px;
  }

  @media screen and (max-width: ${screen480px}){
    width: 20.25px;
    height: 5.62px;
  }
`