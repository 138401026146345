import { StaticImage } from 'gatsby-plugin-image'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SwipeableViews from 'react-swipeable-views'
import BackgroundImage from 'gatsby-background-image'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import Lodash from 'lodash'

import useWindowDimensions from '../utils/useWindowDimensions'
import { screen1920px, screen480px, screen1000px, screen768px, screen400px } from '../constants'

import ArrowLeftIcon from '../assets/images/arrow-left.svg'
import ArrowRightIcon from '../assets/images/arrow-right.svg'

type Photo = {
  title: string,
  url: string,
  photo: () => JSX.Element,
}
type Page = {
  photos: Photo[],
  index: number,
}

const buildTripUrl = (tripId: string) => `https://ttravel.me/trips/${tripId}`;

const availableTrips = [
  { title: 'iceland', photo: () => <StaticImage src='../assets/trips/iceland.png' alt='iceland' /> , url: buildTripUrl('08d6c981-4754-8d90-5fa9-1c8406945e17')},
  { title: 'portugal', photo: () => <StaticImage src='../assets/trips/portugal.png' alt='portugal' /> , url: buildTripUrl('08d6c984-8a10-793f-acf0-bc9c4ee4816e')},
  { title: 'faroe', photo: () => <StaticImage src='../assets/trips/faroe.png' alt='faroe' /> , url: buildTripUrl('08d72648-aaf5-be2c-f385-46ccefb63dec')},
  { title: 'canary', photo: () => <StaticImage src='../assets/trips/canary.png' alt='canary' /> , url: buildTripUrl('08d7c11a-5a24-6dc5-ee8b-71171cf9aad9')},
  { title: 'indonesia', photo: () => <StaticImage src='../assets/trips/indonesia.png' alt='indonesia' /> , url: buildTripUrl('a0108433-cd27-4ced-1c0c-08d64a6fe4cb')},
  { title: 'montenegro', photo: () => <StaticImage src='../assets/trips/montenegro.png' alt='montenegro' /> , url: buildTripUrl('08d7894c-593a-9013-8f1f-67e04bcd3854')},
  { title: 'tenerife', photo: () => <StaticImage src='../assets/trips/tenerife.png' alt='tenerife' /> , url: buildTripUrl('08d7cf5a-27a1-235e-8b5e-5badaf7fb577')},
  { title: 'big-trip-ukraine', photo: () => <StaticImage src='../assets/trips/big-trip-ukraine.png' alt='big-trip-ukraine' /> , url: buildTripUrl('08d92cbe-7787-f7cd-eb13-dd5a2a00a685')},
  { title: 'bali', photo: () => <StaticImage src='../assets/trips/bali.png' alt='bali' /> , url: buildTripUrl('1c928ad3-f6d7-43d0-1c0b-08d64a6fe4cb')},
  { title: 'albania', photo: () => <StaticImage src='../assets/trips/albania.png' alt='albania' /> , url: buildTripUrl('08db78af-1879-4f16-8322-c533554b6721')},
  { title: 'apulia', photo: () => <StaticImage src='../assets/trips/apulia.png' alt='apulia' /> , url: buildTripUrl('08d81a83-23e3-db4c-e6a4-898e51b021c1')},
  { title: 'ukraine-west', photo: () => <StaticImage src='../assets/trips/ukraine-west.png' alt='ukraine-west' /> , url: buildTripUrl('08d7f419-8553-9503-1d8d-831d3b96dbb2')},
  { title: 'chinkve-terre', photo: () => <StaticImage src='../assets/trips/chinkve-terre.png' alt='chinkve-terre' /> , url: buildTripUrl('08d8733d-79fc-6749-32bc-cc577e960bef')},
  { title: 'odesa', photo: () => <StaticImage src='../assets/trips/odesa.png' alt='odesa' /> , url: buildTripUrl('08d8a1c9-36e4-40c1-5c32-389583233c69')},
  { title: 'chernihiv', photo: () => <StaticImage src='../assets/trips/chernihiv.png' alt='chernihiv' /> , url: buildTripUrl('08d89133-fcdd-b531-e2ba-e8a0ca1726c5')},
  { title: 'sofia', photo: () => <StaticImage src='../assets/trips/sofia.png' alt='sofia' /> , url: buildTripUrl('08da91a1-5f23-9ed5-ad74-fff34038cb88')},
  { title: 'sri-lanka', photo: () => <StaticImage src='../assets/trips/sri-lanka.png' alt='sri-lanka' /> , url: buildTripUrl('08da68d3-b750-be43-3ea8-905839434c1a')},
  { title: 'spain', photo: () => <StaticImage src='../assets/trips/spain.png' alt='spain' /> , url: buildTripUrl('08da68d3-c746-a02f-c1bc-fc3fc72f81ad')},
  { title: 'venice', photo: () => <StaticImage src='../assets/trips/venice.png' alt='venice' /> , url: buildTripUrl('08daa3a9-a850-ef37-8d74-a4f1f0f43acc')},
  { title: 'iceland-highlands', photo: () => <StaticImage src='../assets/trips/iceland-highlands.png' alt='iceland-highlands' /> , url: buildTripUrl('08d9a480-4d0a-b3a8-5ab5-709adc2794a6')},
]

const Trips = () => {
  const { width, height } = useWindowDimensions();
  const { t } = useTranslation("translation", { keyPrefix: 'trips' });
  const tr = (value: string): string => t(value)

  const [pages, setPages] = useState<Page[]>([])
  const [currentPageIndex, setCurrentPageIndex] = useState<number>(0)

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background3.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  useEffect(() => {
    const size = width > 480
      ? 12
      : 8

    const chunks = Lodash.chunk(availableTrips, size)
    setPages(chunks.map((i, index) => {
      return { photos: i, index: index }
    }))
    setCurrentPageIndex(0)
  }, [width])

  const nextPage = () => {
    if (currentPageIndex + 1 < pages.length) {
      setCurrentPageIndex(currentPageIndex + 1);
    }
  }

  const prevPage = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1);
    }
  }

  return (
    <Wrapper id='trips' fluid={data.desktop.childImageSharp.fluid}>
      <ContentWrapper className='section'>
        <Title>{tr('title')}</Title>
        <GalleryWrapper>
          <Gallery>
            <ArrowLeft onClick={prevPage}><ArrowLeftIconComponent /></ArrowLeft>
            <SwipeableViews index={currentPageIndex}
              onChangeIndex={i => setCurrentPageIndex(i)} resistance>
              {pages.map((page, index) => (
                <Slide key={index}>
                  {page.photos.map((i, photoIndex) => (
                    <SlidePhoto key={photoIndex} href={i.url}>
                      <PhotoName>{tr(`availableTrips.${i.title}`)}</PhotoName>
                      <Photo>
                        {i.photo()}
                      </Photo>
                    </SlidePhoto>
                  ))}
                </Slide>
              ))}
            </SwipeableViews>
            <ArrowRight onClick={nextPage}><ArrowRightIconComponent /></ArrowRight>
          </Gallery>
          <Dots>
            {pages.map((i, index) => currentPageIndex === index ?
              <ActiveDot key={index} /> :
              <InactiveDot key={index} onClick={() => setCurrentPageIndex(index)} />)}
          </Dots>
        </GalleryWrapper>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Trips

const Wrapper = styled(BackgroundImage)`
  font-style: normal;
  background-position: bottom 0 left 50%;
  background-size: cover;
  position: relative;
`

const ContentWrapper = styled.div`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.1));
  padding-top: 80px;
  padding-bottom: 30px;
  color: #FFFFFF;

  @media screen and (max-width: ${screen768px}){
    padding-right: 20px;
  }

  @media screen and (max-width: ${screen480px}){
    padding-right: 5px;
  }
`
const Title = styled.h2`
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  font-size: 5.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 2.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 3.1rem;
  }
`

const GalleryWrapper = styled.div`
  margin-top: 30px;
  margin-left: -100px;
  @media screen and (max-width: ${screen1920px}){
    margin-left: -52.08px;
  }
  @media screen and (max-width: ${screen1000px}){
    margin-left: 0;
  }
`
const Gallery = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Arrow = styled.div`
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  max-height: 50px;
  border-radius: 50px;
  background: #FF8557;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @media screen and (max-width: ${screen1000px}){
    display: none;
  }
  @media screen and (max-width: ${screen1920px}){
    min-width: 26.04px;
    min-height: 26.04px;
    max-width: 26.04px;
    max-height: 26.04px;
    border-radius: 26.04px;
  }
  @media screen and (max-width: ${screen480px}){
    min-width: 50px;
    min-height: 50px;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50px;
  }
`

const ArrowLeft = styled(Arrow)`
  margin-right: 50px;
  @media screen and (max-width: ${screen1920px}){
    margin-right: 26.04px;
  }
  @media screen and (max-width: ${screen480px}){
    margin-right: 40px;
  }
`
const ArrowRight = styled(Arrow)`
  margin-left: 10px;
  margin-right: -10px;
  @media screen and (max-width: ${screen1920px}){
    margin-left: 10px;
    margin-right: -10px;
  }
  @media screen and (max-width: ${screen480px}){
    margin-left: 10px;
    margin-right: -10px;
  }
`

const ArrowLeftIconComponent = styled(ArrowLeftIcon)`
  @media screen and (max-width: ${screen1920px}){
    width: 3.72px;
  }
  @media screen and (max-width: ${screen480px}){
    width: 20px;
  }
`
const ArrowRightIconComponent = styled(ArrowRightIcon)`
  @media screen and (max-width: ${screen1920px}){
    width: 3.72px;
  }
  @media screen and (max-width: ${screen480px}){
    width: 20px;
  }
`

const Slide = styled.div`
  width: 100%;
  display: grid;
  overflow: hidden;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: ${screen480px}){
    grid-template-columns: 1fr 1fr;
  }
`

const SlidePhoto = styled.a`
  margin-right: 30px;
  text-decoration: none;
  color: white;
  @media screen and (max-width: ${screen1920px}){
    margin-right: 15px;
  }
  @media screen and (max-width: ${screen480px}){
    margin-right: 11px;
  }
`
const PhotoName = styled.h4`
  margin-bottom: 5px;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  min-height: 5.2rem;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
    min-height: 4rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.8rem;
    min-height: 5.2rem;
  }
  @media screen and (max-width: ${screen400px}){
    font-size: 1.4rem;
    min-height: 5.2rem;
  }
`

const Photo = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  /* height: 130px; */
  max-width: 358px;
  @media screen and (max-width: ${screen1920px}){
    max-width: 160.45px;
  }
  @media screen and (max-width: ${screen480px}){
    max-width: 215px;
  }

  img {
    border-radius: 15px;
    @media screen and (max-width: ${screen1920px}) {
      border-radius: 7.8125px;
    }
    @media screen and (max-width: ${screen480px}) {
      border-radius: 9.38px;
    }
  }
`

const Dots = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`

const Dot = styled.div`
  cursor: pointer;
  min-width: 12px;
  max-width: 12px;
  min-height: 12px;
  max-height: 12px;
  border-radius: 12px;
  margin: 5px;
`

const ActiveDot = styled(Dot)`
  background: #FFFFFF;
`
const InactiveDot = styled(Dot)`
  background: #FFFFFF;
  opacity: 0.1;
`
