import React, { useState } from "react"
import { GlobalStyle } from "./GlobalStyle"
import Header from "./Header"
import Menu from "./Menu"

const Layout = ({ children }) => {
  const [closed, setClosed] = useState<boolean>(true)

  return (
    <>
      <GlobalStyle />
      <Header openMenu={() => setClosed(false)} />
      <Menu closeRequested={() => setClosed(true)} closed={closed} />
      <main>{children}</main>
    </>
  )
}

export default Layout
