import { createGlobalStyle } from 'styled-components'
import { screen1520px, screen1920px, screen480px, screen700px, screen768px } from '../constants'

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: local('Montserrat'), url('/fonts/Montserrat-VariableFont_wght.ttf') format('truetype');
    font-weight: 300 900;
  };

  * {
    font-family: 'Montserrat';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  };

  html {
    overflow-x: hidden;
    font-size: 62.5%;
  }

  body {
    font-size: 1.6rem;
  }

  .section {
    padding-left: calc((100vw - ${screen1520px})/2);
    padding-right: calc((100vw - ${screen1520px})/2);

    @media screen and (max-width: ${screen1920px}){
      padding-left: calc((100vw - ${screen700px})/2);
      padding-right: calc((100vw - ${screen700px})/2);
    }

    @media screen and (max-width: ${screen768px}){
      padding-left: 34px;
      padding-right: 34px;
    }

    @media screen and (max-width: ${screen480px}){
      padding-left: 20px;
      padding-right: 20px;
    }
  }
`
