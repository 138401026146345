import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { screen1920px, screen480px, screen768px } from '../constants'
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Logo from './Logo'

import TelegramIcon from '../assets/images/telegram-white.svg'
import InstagramIcon from '../assets/images/instagram-white.svg'
import YoutubeIcon from '../assets/images/youtube-white.svg'
import { noticeAppStoreButtonClicked, noticeGooglePlayButtonClicked, noticeInstagramClicked, noticeTelegramClicked, noticeYoutubeClicked } from '../utils/analytics'

const siteMap = [
  { title: 'main', link: '#intro' },
  { title: 'forWhom', link: '#for-whom' },
  { title: 'aboutUs', link: '#about' },
  { title: 'availableTrips', link: '#trips' },
  { title: 'insideItinerary', link: '#inside-itinerary' },
  { title: 'howConstructorWorks', link: '#constructor' },
  { title: 'UkraineTrip', link: '#ukraine' },
  { title: 'Feedbacks', link: '#feedbacks' },
]

const Footer = () => {
  const { t, i18n } = useTranslation("translation", { keyPrefix: 'footer' });
  const tr = (value: string): string => t(value)

  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "background6.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 3840) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  return (
    <Wrapper fluid={data.desktop.childImageSharp.fluid}>
      <ContentWrapper className='section'>
        <Content1>
          <Column>
            <Title>{tr('title').split('\n').map((i, index) => <div key={index}>{i}</div>)}</Title>
            <SocialButtons>
              <Link onClick={noticeTelegramClicked} target='_blank' href='https://t.me/ttravel_support'><TelegramIcon /></Link>
              <Link onClick={noticeInstagramClicked} target='_blank' href='https://www.instagram.com/ttravel.me/'><InstagramIcon /></Link>
              <Link onClick={noticeYoutubeClicked} target='_blank' href='https://www.youtube.com/@tTravelMe'><YoutubeIcon /></Link>
            </SocialButtons>
            <Email href="mailto:info@ttravel.me">info@ttravel.me</Email>

            <Title>{tr('title2')}</Title>
            <StoreButtons>
              <StoreButton onClick={noticeAppStoreButtonClicked} href='https://apps.apple.com/ua/app/ttravel/id1567879792' target='_blank'>
                <StaticImage src='../assets/images/apple-store.png' alt='apple store' />
              </StoreButton>
              <StoreButton onClick={noticeGooglePlayButtonClicked} href='https://play.google.com/store/apps/details?id=me.ttravel.rnjs' target='_blank'>
                <StaticImage src='../assets/images/google-play.png' alt='play market' />
              </StoreButton>
            </StoreButtons>
          </Column>
          <Column>
            <Title>{tr('sitemap')}</Title>
            <Items>
              {siteMap.map((i, index) => <Item key={index} href={i.link}>{tr(i.title)}</Item>)}
            </Items>
          </Column>
        </Content1>
        <Content2>
          <ColumnWithLang>
            <Languages>
              <Lang as='a' active={i18n.language === 'uk'} href='/'>UA</Lang>
              <Separator>|</Separator>
              <Lang as='a' active={i18n.language === 'en'} href='/en/'>EN</Lang>
            </Languages>

            <Logo />
          </ColumnWithLang>
        </Content2>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Footer


const Wrapper = styled(BackgroundImage)`
  font-style: normal;
  background-position: 40% 0;
  background-size: cover;
  position: relative;
`

const ContentWrapper = styled.div`
  color: #FFFFFF;
  display: grid;
  grid-template-columns: 80% 20%;
  padding-top: 80px;
  padding-bottom: 80px;

  @media screen and (max-width: ${screen768px}){
    grid-template-columns: 1fr;
  }
`

const Content1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
const Content2 = styled.div`
  display: flex;
`

const Column = styled.div`
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:nth-of-type(2) {
    padding-right: 100px;
  }

  @media screen and (max-width: ${screen480px}){
    padding-right: 5px;
    &:nth-of-type(2) {
      padding-right: 5px;
    }
  }
`

const ColumnWithLang = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media screen and (max-width: ${screen768px}){
    margin-top: 100px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 85vw;
  }
`

const StoreButtons = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media screen and (max-width: ${screen768px}){
    grid-template-columns: 1fr;
  }
`

const StoreButton = styled(OutboundLink)`
  margin-right: 10px;
  margin-bottom: 5px;
  width: 170px;

  @media screen and (max-width: ${screen1920px}){
    width: 118.75px;
  }

  @media screen and (max-width: ${screen480px}){
    width: 142px;
  }
`

const SocialButtons = styled.div`
  display: flex;
  align-items: center;
  margin: 5px 0;

  a {
    margin: 0 15px;
  }
  a:first-of-type {
    margin-left: 0;
  }
`

const Email = styled(OutboundLink)`
  font-weight: 400;
  margin-bottom: 50px;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const Link = styled.a`
  width: 30px;
  display: flex;
`

const Title = styled.h3`
  font-weight: 800;
  line-height: 105%;
  text-transform: uppercase;
  color: #FF8557;
  font-size: 3.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.9rem;
  }
`

const Items = styled.div`
  margin-top: 10px;
`

const Item = styled.a`
  font-weight: 400;
  line-height: 120%;
  margin: 10px 0;
  display: block;
  text-decoration: none;
  color: #FFFFFF;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
`

const Languages = styled.div`
  display: flex;
`

const Lang = styled(Title) <{ active: boolean }>`
  font-weight: ${p => p.active ? 800 : 400};
  cursor: pointer;
  text-decoration: none;
`

const Separator = styled(Title)`
  font-weight: 400;
  margin: 0 10px;
`
