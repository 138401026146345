
export const noticeAppStoreButtonClicked = () => noticeEvent({eventName: 'AppStoreButtonClicked'});
export const noticeGooglePlayButtonClicked = () => noticeEvent({eventName: 'GooglePlayButtonClicked'});
export const noticeDonateButtonClicked = () => noticeEvent({eventName: 'DonateButtonClicked'});
export const noticeLinkedInShambalovClicked = () => noticeEvent({eventName: 'LinkedInShambalovClicked'});
export const noticeLinkedInBuratynskyClicked = () => noticeEvent({eventName: 'LinkedInBuratynskyClicked'});
export const noticeInstagramClicked = () => noticeEvent({eventName: 'InstagramClicked'});
export const noticeYoutubeClicked = () => noticeEvent({eventName: 'YoutubeClicked'});
export const noticeTelegramClicked = () => noticeEvent({eventName: 'TelegramClicked'});
export const noticeEmailClicked = () => noticeEvent({eventName: 'EmailClicked'});

const noticeEvent = (opts: { eventName: string }) => {
  // console.log('notice event', opts.eventName);
  if (typeof window === "undefined" || !window.gtag) return;
  // console.log('notice event sent', opts.eventName);

  window.gtag("event", opts.eventName, {
    event_category: "outbound",
  });
}
