export const screen1920px = '1920px'
export const screen1720px = '1720px'
export const screen1520px = '1520px'
export const screen1200px = '1200px'
export const screen1000px = '1000px'
export const screen768px = '768px'
export const screen700px = '700px'
export const screen480px = '480px'
export const screen400px = '400px'
export const screen360px = '360px'
