import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components'
import { screen1920px, screen400px, screen480px } from '../constants'
import { OutboundLink } from "gatsby-plugin-google-gtag"

import TelegramIcon from '../assets/images/telegram.svg'
import InstagramIcon from '../assets/images/instagram.svg'
import YoutubeIcon from '../assets/images/youtube.svg'
import { noticeInstagramClicked, noticeTelegramClicked, noticeYoutubeClicked } from '../utils/analytics';

const Plans = () => {
  const { t } = useTranslation("translation", { keyPrefix: 'plans' });
  const tr = (value: string): string => t(value)

  return (
    <Wrapper>
      <ContentWrapper className='section'>
        <Columns>
          <Column>
            <Title>{tr('title')}</Title>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item1')}</Text>
            </Item>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item2')}</Text>
            </Item>
            <Item>
              <LineWrapper><Line /></LineWrapper>
              <Text>{tr('item3')}</Text>
            </Item>
          </Column>
          <Column>
            <Title css={{ 'margin-bottom': 0 }}>{tr('title2')}</Title>
            <SocialButtons>
              <Link onClick={noticeTelegramClicked} target='_blank' href='https://t.me/ttravel_support'><TelegramIcon /></Link>
              <Link onClick={noticeInstagramClicked} target='_blank' href='https://www.instagram.com/ttravel.me/'><InstagramIcon /></Link>
              <Link onClick={noticeYoutubeClicked} target='_blank' href='https://www.youtube.com/@tTravelMe'><YoutubeIcon /></Link>
            </SocialButtons>

            <Title>{tr('title3').split('\n').map((i, index) => <div key={index}>{i}</div>)}</Title>
            <Email href="mailto:info@ttravel.me">info@ttravel.me</Email>
          </Column>
        </Columns>
      </ContentWrapper>
    </Wrapper>
  )
}

export default Plans

const Wrapper = styled.div`
  font-style: normal;
  position: relative;
  background: #FF8557;
`

const ContentWrapper = styled.div`
  color: #171717;
  font-style: normal;
  padding-top: 50px;
  padding-bottom: 50px;
`

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
`

const Title = styled.div`
  font-weight: 800;
  text-transform: uppercase;
  padding-right: 5px;
  margin-bottom: 10px;
  font-size: 3.0rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.9rem;
  }
  @media screen and (max-width: ${screen400px}){
    font-size: 1.5rem;
  }
`

const Item = styled.div`
  margin-right: 20px;
  margin-top: 10px;
  display: flex;
`

const LineWrapper = styled.div``

const Line = styled.div`
  height: 1px;
  width: 100vw;
  margin-left: -101vw;
  margin-top: 0.8em;
  background-color: #171717;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${screen400px}){
    font-size: 1.0rem;
  }
`

const SocialButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  a {
    margin: 0 15px;
  }
  a:first-of-type {
    margin-left: 0;
  }
`

const Email = styled(OutboundLink)`
  font-weight: 400;
  text-decoration: none;
  color: #171717;
  font-size: 2.3rem;
  @media screen and (max-width: ${screen1920px}){
    font-size: 1.2rem;
  }
  @media screen and (max-width: ${screen480px}){
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${screen400px}){
    font-size: 1.0rem;
  }
`

const Link = styled.a`
  display: flex;
  width: 30px;
  @media screen and (max-width: ${screen1920px}){
    width: 30px;
  }
  @media screen and (max-width: ${screen480px}){
    width: 24px;
  }
`
